exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-documentation-js": () => import("./../../../src/pages/documentation.js" /* webpackChunkName: "component---src-pages-documentation-js" */),
  "component---src-pages-getting-started-js": () => import("./../../../src/pages/getting-started.js" /* webpackChunkName: "component---src-pages-getting-started-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-playground-js": () => import("./../../../src/pages/playground.js" /* webpackChunkName: "component---src-pages-playground-js" */),
  "component---src-pages-stdlib-stdlib-section-slug-js": () => import("./../../../src/pages/stdlib/{StdlibSection.slug}.js" /* webpackChunkName: "component---src-pages-stdlib-stdlib-section-slug-js" */),
  "component---src-pages-user-manual-user-manual-markdown-slug-js": () => import("./../../../src/pages/user-manual/{UserManualMarkdown.slug}.js" /* webpackChunkName: "component---src-pages-user-manual-user-manual-markdown-slug-js" */)
}

